<template>
  <div>
    <h3>Kedves Párok!</h3>

    <p>
      Szeretettel hívunk bennetek idén is a Házasság Hete eseményeire "<i>Színezd újra</i>" mottóval. Hetek óta ködben élünk, minden szürke, a színes karácsonyfákat már kitettük a házból, még olyan messze a tavasz... Próbáltam elfújni a ködöt, de nem sikerült...aztán főztem egy finom kávét a feleségemnek egy meglepetés puszival és "kisütött a nap".
    </p>

    <p>
      Idén ilyen apró kedvességekre, figyelmességekre biztatunk benneteket! Összeállítottunk egy <router-link to="/trials">FELADATLAPOT</router-link>, amiben 21 apró ötlettel szeretnénk segíteni a "színezést", a kapcsolatotok felfűtését. Hármas blokkokba csomagoltuk őket, hogy minden napra lehessen miből választani, de legyetek teljesen szabadok! Mazsolázzatok kedvetekre, lényeg, hogy minden nap kicsit foglalkozzatok egymással, a házasságotokkal!
      Találjatok ki újakat, küldjétek meg nekünk, hogy a jövőben másoknak is tudjunk segíteni vele! Idén ti lesztek a Házasség Hete előadói😉
    </p>

    <p>
      Többen közületek az előző években is részt vettetek a programokon, tudjátok, hogy naprakész információkkal segítünk titeket e-mailen keresztül. Ha eddig még nem adtátok meg elérhetőségeteket, kérünk osszátok meg velünk a <a href="mailto:marosvet75 [kukac] gmail.com">marosvet75 [kukac] gmail.com</a> címre küldött e-maillel. Elképzelhető, sőt várható, hogy apró "noszogató" emaileket fogtok kapni a hét során, bocsássátok ezt meg nekünk!
    </p>
    
    <p>
      A Házasság Hetére idén is mindenkit várunk, nemcsak házaspárokat, hanem mindazokat, akik hisznek az életre szóló elkötelezett párkapcsolat értékében. Fiatal házasok és nagyon rég óta fiatal házasok is gyertek!!! Jubiláló házasok - 5,10,15.....55,60,65 éve volt az esküvőtök - jelentkezzetek, rátok kitüntetett figyelemmel gondolunk!
    </p>

    <p>Adományaitokat a programok költségeinek fedezésére köszönettel fogadjuk.</p>

    <p class="right-align">HH Team</p>

    <h2>Közös imádság</h2>
    <div class="list-wrapper">
      <p>
        <strong>Nagymaros, Római Katolikus Templom</strong>
        <br> február 10. hétfő este 20.00, csendes szentségimádás
      </p>
    </div>

    <h2>Házassági fogadalom megújítása</h2>
    <div class="list-wrapper">
      <p>
        <strong>Nagymaros, Római Katolikus Templom</strong>
        <br> február 15. szombat este 18.00, záró szentmise
      </p>
      <p>
        <strong>Nagymaros, Református Templom</strong>
        <br> február 16. vasárnap délelőtt 10.00, istentisztelet
      </p>
    </div>

    <h2>Gyertyafényes vacsora</h2>
    <div class="list-wrapper">
      <p>
        Idén is szervezünk gyertyafényes vacsorát, kérünk jelezzétek részvételi szándékotokat a 
        <a href="mailto:marosvet75 [kukac] gmail.com">marosvet75 [kukac] gmail.com</a> címre küldött e-maillel.
      </p>
      <p>
        <strong>Nagymaros, Szent Márton Közösségi Ház</strong>
        <br> február 15. szombat este 19.00
      </p>
    </div>

    <h2>Országos programajánló</h2>
    <div class="list-wrapper ads">
      <p>
        <a href="https://www.hazassaghete.hu/programok/viharallo-szerelem-3/">Viharálló szerelem</a> (Felsőgöd)
      </p>
      <p>
        <a href="https://www.hazassaghete.hu/programok/ujra-kettesben/">Újra kettesben</a> (Bp. 5. kerület)
      </p>
      <p>
        <a href="https://www.hazassaghete.hu/programok/torespontok-menten-nem-pillanatragasztoval/">Töréspontok mentén, nem pillanatragasztóval</a> (Online)
      </p>
      <p>
        <a href="https://www.hazassaghete.hu/programok/oszinten-a-hazassagrol-paratlan-beszelgetes-kozismert-parokkal-2/">Őszintén a házasságról</a> (Bp. 8. kerület)
      </p>
      <p>
        <a href="https://www.hazassaghete.hu/programok/2441/">Az AI és a szerelem</a> (Bp. 11. kerület)
      </p>
      <p>
        <a href="https://www.hazassaghete.hu/programok/minden-madar-tarsat-valaszt-valoban-ismerkedes-es-parvalasztas-egykor-es-ma/">Minden Madár Társat Választ…</a> (Bp. 8. kerület)
      </p>
      <p>
        <a href="https://www.hazassaghete.hu/programok/zaro-szentmise-hazassag-hete-2025/">Országos záró szentmise.</a> <span>(Bp. 5. kerület)</span>
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'main'
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .ads p {
    display: flex;
    justify-content: space-between;

    a {
      display: inline-block;
      margin-right: 10px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
</style>