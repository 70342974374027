var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',[_vm._v("Kedves Párok!")]),_vm._m(0),_c('p',[_vm._v(" Idén ilyen apró kedvességekre, figyelmességekre biztatunk benneteket! Összeállítottunk egy "),_c('router-link',{attrs:{"to":"/trials"}},[_vm._v("FELADATLAPOT")]),_vm._v(", amiben 21 apró ötlettel szeretnénk segíteni a \"színezést\", a kapcsolatotok felfűtését. Hármas blokkokba csomagoltuk őket, hogy minden napra lehessen miből választani, de legyetek teljesen szabadok! Mazsolázzatok kedvetekre, lényeg, hogy minden nap kicsit foglalkozzatok egymással, a házasságotokkal! Találjatok ki újakat, küldjétek meg nekünk, hogy a jövőben másoknak is tudjunk segíteni vele! Idén ti lesztek a Házasség Hete előadói😉 ")],1),_vm._m(1),_c('p',[_vm._v(" A Házasság Hetére idén is mindenkit várunk, nemcsak házaspárokat, hanem mindazokat, akik hisznek az életre szóló elkötelezett párkapcsolat értékében. Fiatal házasok és nagyon rég óta fiatal házasok is gyertek!!! Jubiláló házasok - 5,10,15.....55,60,65 éve volt az esküvőtök - jelentkezzetek, rátok kitüntetett figyelemmel gondolunk! ")]),_c('p',[_vm._v("Adományaitokat a programok költségeinek fedezésére köszönettel fogadjuk.")]),_c('p',{staticClass:"right-align"},[_vm._v("HH Team")]),_c('h2',[_vm._v("Közös imádság")]),_vm._m(2),_c('h2',[_vm._v("Házassági fogadalom megújítása")]),_vm._m(3),_c('h2',[_vm._v("Gyertyafényes vacsora")]),_vm._m(4),_c('h2',[_vm._v("Országos programajánló")]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Szeretettel hívunk bennetek idén is a Házasság Hete eseményeire \""),_c('i',[_vm._v("Színezd újra")]),_vm._v("\" mottóval. Hetek óta ködben élünk, minden szürke, a színes karácsonyfákat már kitettük a házból, még olyan messze a tavasz... Próbáltam elfújni a ködöt, de nem sikerült...aztán főztem egy finom kávét a feleségemnek egy meglepetés puszival és \"kisütött a nap\". ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" Többen közületek az előző években is részt vettetek a programokon, tudjátok, hogy naprakész információkkal segítünk titeket e-mailen keresztül. Ha eddig még nem adtátok meg elérhetőségeteket, kérünk osszátok meg velünk a "),_c('a',{attrs:{"href":"mailto:marosvet75 [kukac] gmail.com"}},[_vm._v("marosvet75 [kukac] gmail.com")]),_vm._v(" címre küldött e-maillel. Elképzelhető, sőt várható, hogy apró \"noszogató\" emaileket fogtok kapni a hét során, bocsássátok ezt meg nekünk! ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-wrapper"},[_c('p',[_c('strong',[_vm._v("Nagymaros, Római Katolikus Templom")]),_c('br'),_vm._v(" február 10. hétfő este 20.00, csendes szentségimádás ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-wrapper"},[_c('p',[_c('strong',[_vm._v("Nagymaros, Római Katolikus Templom")]),_c('br'),_vm._v(" február 15. szombat este 18.00, záró szentmise ")]),_c('p',[_c('strong',[_vm._v("Nagymaros, Református Templom")]),_c('br'),_vm._v(" február 16. vasárnap délelőtt 10.00, istentisztelet ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-wrapper"},[_c('p',[_vm._v(" Idén is szervezünk gyertyafényes vacsorát, kérünk jelezzétek részvételi szándékotokat a "),_c('a',{attrs:{"href":"mailto:marosvet75 [kukac] gmail.com"}},[_vm._v("marosvet75 [kukac] gmail.com")]),_vm._v(" címre küldött e-maillel. ")]),_c('p',[_c('strong',[_vm._v("Nagymaros, Szent Márton Közösségi Ház")]),_c('br'),_vm._v(" február 15. szombat este 19.00 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-wrapper ads"},[_c('p',[_c('a',{attrs:{"href":"https://www.hazassaghete.hu/programok/viharallo-szerelem-3/"}},[_vm._v("Viharálló szerelem")]),_vm._v(" (Felsőgöd) ")]),_c('p',[_c('a',{attrs:{"href":"https://www.hazassaghete.hu/programok/ujra-kettesben/"}},[_vm._v("Újra kettesben")]),_vm._v(" (Bp. 5. kerület) ")]),_c('p',[_c('a',{attrs:{"href":"https://www.hazassaghete.hu/programok/torespontok-menten-nem-pillanatragasztoval/"}},[_vm._v("Töréspontok mentén, nem pillanatragasztóval")]),_vm._v(" (Online) ")]),_c('p',[_c('a',{attrs:{"href":"https://www.hazassaghete.hu/programok/oszinten-a-hazassagrol-paratlan-beszelgetes-kozismert-parokkal-2/"}},[_vm._v("Őszintén a házasságról")]),_vm._v(" (Bp. 8. kerület) ")]),_c('p',[_c('a',{attrs:{"href":"https://www.hazassaghete.hu/programok/2441/"}},[_vm._v("Az AI és a szerelem")]),_vm._v(" (Bp. 11. kerület) ")]),_c('p',[_c('a',{attrs:{"href":"https://www.hazassaghete.hu/programok/minden-madar-tarsat-valaszt-valoban-ismerkedes-es-parvalasztas-egykor-es-ma/"}},[_vm._v("Minden Madár Társat Választ…")]),_vm._v(" (Bp. 8. kerület) ")]),_c('p',[_c('a',{attrs:{"href":"https://www.hazassaghete.hu/programok/zaro-szentmise-hazassag-hete-2025/"}},[_vm._v("Országos záró szentmise.")]),_vm._v(" "),_c('span',[_vm._v("(Bp. 5. kerület)")])])])
}]

export { render, staticRenderFns }